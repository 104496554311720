import { DatadogLoggingService } from '@edx/frontend-logging';
import IdVerificationPage from '@edx/frontend-plugin-persona';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';

const config = {
  loggingService: DatadogLoggingService,
  PERSONA_ENABLED: true,
  pluginSlots: {},
};

if (config.PERSONA_ENABLED) {
  config.pluginSlots.id_verification_page_plugin = {
    keepDefault: false,
    plugins: [
      {
        op: PLUGIN_OPERATIONS.Insert,
        widget: {
          id: 'id_verification_page_plugin',
          type: DIRECT_PLUGIN,
          RenderWidget: IdVerificationPage,
        },
      },
    ],
  }
}

export default config;
